@import url(https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #000000;
  color: #ffffff;
}

.saira-font {
  font-family: "Saira Condensed", sans-serif;
}
.roboto-font {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  font-family: "Roboto", sans-serif;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding: 0 0px;
}

.section-title h2 {
  font-size: 45px;
  font-family: "Saira Condensed", sans-serif;
  letter-spacing: 1px;
  text-align: center;
  margin: 30px 0;
  text-shadow: 6px 1px 30px #1e7be0, 1px 2px 22px #92f4af;
  color: #E1BE02;
}
.section-title p {
  text-align: center;
}

.btn {
  border: 2px solid transparent;
  padding: 25px 28px;
  font-size: 18px;
  text-transform: uppercase;
  background: #fff000;
  outline: none;
  color: #1E7BE0;
  font-weight: 700;
  transition: 0.3s ease;
  border-radius: 3px;
  position: relative;
  -webkit-clip-path: polygon(17% 0, 100% 0, 100% 83%, 80% 100%, 0 100%, 0 100%, 0 20%);
          clip-path: polygon(17% 0, 100% 0, 100% 83%, 80% 100%, 0 100%, 0 100%, 0 20%);
}
@media (max-width: 1024px) {
.btn{
  -webkit-clip-path: polygon(3% 0, 100% 0, 100% 84%, 96% 100%, 0 100%, 0 100%, 0 20%);
          clip-path: polygon(3% 0, 100% 0, 100% 84%, 96% 100%, 0 100%, 0 100%, 0 20%);
}  
}
.btn:hover {
  cursor: pointer;
  border: 2px solid #E1BE02;
  color: #ffffff;
}
.welcomeBtn{
  -webkit-clip-path: polygon(8% 0, 100% 0, 100% 87%, 92% 100%, 0 100%, 0 100%, 0 13%);
          clip-path: polygon(8% 0, 100% 0, 100% 87%, 92% 100%, 0 100%, 0 100%, 0 13%);
  border: 1px solid #E1BE02;
}

